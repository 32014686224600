<template>
  <div>
    <div class="modal fade" id="Modal_import_solicitud">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cargar Empresas</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalSolicitud"
              @click="limpiarModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="empresa">Empresa</label>
                <v-select
                  :class="[
                    $v.form.empresa_id.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="empresa"
                  placeholder="Nombre"
                  label="razon_social"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.empresas"
                  @search="buscarEmpresas"
                  @input="selectEmpresas()"
                ></v-select>
              </div>
              <div class="form-group col-md-12">
                <label for="ruta_corta">Ruta Corta</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  v-model="form.ruta_corta"
                  :class="
                    $v.form.ruta_corta.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-12">
                <label for="ruta_larga">Ruta Larga</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  v-model="form.ruta_larga"
                  :class="
                    $v.form.ruta_larga.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <button
                type="button"
                class="btn btn-primary"
                @click="save()"
                v-show="!$v.form.$invalid"
              >
                Guardar
                <i class="fas fa-save"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
export default {
  name: "SolicitudManual",
  components: {
    vSelect,
  },

  data() {
    return {
      empresa: [],
      solicitud: null,
      solicitud_diaria_id: null,
      cargues_ruta_larga: null,
      cargues_ruta_corta: null,
      cargues: null,
      listasForms: {
        empresas: [],
      },
      form: {
        empresa_id: null,
        ruta_corta: null,
        ruta_larga: null,
      },
    };
  },
  validations: {
    form: {
      empresa_id: {
        required,
      },
      ruta_corta: {
        required,
      },
      ruta_larga: {
        required,
      },
    },
  },

  methods: {
    llenarModalImport(
      solicitud,
      cargues_ruta_larga,
      cargues_ruta_corta,
      cargues
    ) {
      this.solicitud_diaria_id = solicitud.id;
      this.solicitud = solicitud;
      this.cargues_ruta_larga = cargues_ruta_larga;
      this.cargues_ruta_corta = cargues_ruta_corta;
      this.cargues = cargues;
      this.limpiarModal();
    },

    buscarEmpresas() {
      let me = this;
      me.listasForms.empresas = [];
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url, {
          params: {
            linea_negocio_id: [2],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectEmpresas() {
      this.form.empresa_id = "";
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
      }
    },

    save() {
      this.$parent.$parent.cargando = true;
      let data = {
        solicitudEmpresas: this.$parent.detSolicitudEmpresas,
        solicitud_diaria_id: this.solicitud_diaria_id,
        fecha: this.solicitud.fecha,
        nominacion_id: this.solicitud.nominacion_id,
        cargues_ruta_larga: this.cargues_ruta_larga,
        cargues_ruta_corta: this.cargues_ruta_corta,
        empresa_id: this.form.empresa_id,
        ruta_corta: this.form.ruta_corta,
        ruta_larga: this.form.ruta_larga,
        cargues: this.cargues,
      };
      axios
        .post("/api/hidrocarburos/solicitudes/solicitudManual", data)
        .then((res) => {
          if (res.data.status == 0) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: res.data.message,
            });
            this.limpiarModal();
            this.$parent.$parent.cargando = false;
          } else {
            //this.$refs.closeModalSolicitud.click();
            this.limpiarModal();
            this.$parent.init();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3500,
              timerProgressBar: true,
            });
            this.$parent.$parent.cargando = false;
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true,
          });
          this.$parent.$parent.cargando = false;
        });
    },

    limpiarModal() {
      this.form = {
        empresa_id: null,
        ruta_corta: null,
        ruta_larga: null,
      };
      this.empresa = [];
    },
  },

  mounted() {},
};
</script>
